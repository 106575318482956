import * as React from "react";
import Drawer from "@mui/material/Drawer";

import { useMaterialUIController, setMiniSidenav } from "lib/context";

const AppDrawer = ({
  children,
  openDrawer,
  placement,
  style,
  onClose,
  mediaModalClose = true,
}) => {
  const [open, setOpen] = React.useState(openDrawer);
  const [controller, dispatch] = useMaterialUIController();

  const toggleDrawer = (val) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(val);
    onClose();
    mediaModalClose && setMiniSidenav(dispatch, false);
  };

  React.useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);

  React.useEffect(() => {
    // Minimize the sidenav when the modal is opened
    if (open) {
      setMiniSidenav(dispatch, true);
    }
  }, [open, dispatch]);

  return (
    <Drawer
      anchor={placement ? placement : "right"}
      open={Boolean(open)}
      onClose={toggleDrawer(false)}
      PaperProps={{
        sx: {
          ...style,
          "&.MuiDrawer-paper": {
            backgroundColor: "#f0f2f5 !important",
          },
        },
      }}
    >
      {typeof openDrawer === "boolean" ? children : openDrawer}
    </Drawer>
  );
};
export default AppDrawer;
