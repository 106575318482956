import { Paper, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import BackButton from "ui/BackButton";
import CarrierForm from "./CarrierForm";
import MDBadge from "lib/components/MDBadge";
import { saveCarrier } from "services/CarrierAPI";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useHttp from "hooks/use-http";
import AppConstants from "constants/AppConstants";
import { INBOUND_CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";

const AddCarrier = ({ onBack, onCreated }) => {
  const token = useSelector((state) => state.user.token);

  const {
    sendRequest: saveCarrierFn,
    data,
    status,
    error,
  } = useHttp(saveCarrier, false);

  const [carrierData, setCarrierData] = useState({
    carrier_name: "",
    http_method: "POST",
    encoding_type: "DEFAULT",
    content_type: "application/json",
    max_length: 160,
    send_url: "",
    request_body: {},
    callback_type: "",
    later: "",
    media_type: "",
    response_body: "",
    limit: 0,
    mediaModalClose: true,
  });
 

  // editHook flag to decide whether to sync webhook with Carrier Name or not based on Add Carrier or Edit Carrier
  const [inbound, setInbound] = useState({ editHook: true }); 

  // Helper function to check if a value is empty
  const isEmpty = (value) => {
    return value == null || (typeof value === "string" && value.trim() === "");
  };

  const onSubmit = (req) => {
    const outboundRequestBody = carrierData?.request_body;

    // Validating Response Callback Type In Outbound
    if (!carrierData?.callback_type) {
      toast.error("Please select response type");
      return;
    }

    if (carrierData?.callback_type === "later" && !carrierData?.later) {
      toast.error("Please select later to track for response");
      return;
    }

    const validateParams = () => {
      const selectedParams = new Set();
      const keysSet = new Set();
      const duplicates = new Set();
      const keyValue = inbound?.body;

      // console.log("Key Value", keyValue);
      keyValue.forEach((item) => {
        // Check if param exists and value is not empty
        if (!isEmpty(item?.param) && !isEmpty(item?.key)) {
          selectedParams.add(item.param);
        }

        // Track duplicate keys
        if (item.key) {
          if (keysSet.has(item.key)) {
            duplicates.add(item.key);
          } else {
            keysSet.add(item.key);
          }
        }
      });

      // Track the missing params by comparing to required options
      const missingParams = INBOUND_CARRIER_REQ_PARAMS_OPT.filter(
        (option) => !selectedParams.has(option.key) && option.key !== "media"
      );

      return { missingParams, duplicates: [...duplicates] };
    };

    const getAuthObject = () => {
      const authObject = outboundRequestBody?.auth;

      console.log("auth Key", authObject);

      if (authObject.key === "basic_auth") {
        return {
          type: "BASIC",
          username: authObject?.value?.userName,
          password: authObject?.value?.pass,
        };
      }

      if (authObject.key === "bearer_token") {
        return {
          type: "BEARER",
          token: authObject.value,
        };
      }

      return {
        type: "NONE",
      };
    };

    // Perform validation
    if (isEmpty(carrierData.carrier_name)) {
      toast.error("Please fill Carrier Name");
      return;
    }

    if (isEmpty(carrierData?.response_body)) {
      toast.error("Response body Not Found in Outbound Config");
      return;
    }

    // Perform validation before making the request
    const { missingParams, duplicates } = validateParams();

    console.log("Values", missingParams, duplicates, inbound);

    if (missingParams.length > 0) {
      const missingKeys = missingParams.map((param) => param.value).join(", ");
      toast.error(
        `Please define values in Inbound Section for the following params: ${missingKeys}`
      );
      return;
    }

    if (duplicates.length > 0) {
      const duplicateKeys = duplicates.join(", ");
      toast.error(
        `Duplicate keys found in Inbound Section: ${duplicateKeys}. Keys must be unique.`
      );
      return;
    }

    // Preparing the Request Body for Save Carrier API
    console.log("Outbound Request Body", getAuthObject());
    const requestBody = {
      carrier_name: carrierData?.carrier_name,
      limit: carrierData?.limit,
      outbound: {
        http_method: carrierData?.http_method,
        api_endpoint: carrierData?.send_url,
        headers: outboundRequestBody?.headers,
        auth: getAuthObject(),
        params: outboundRequestBody?.params,
        body: outboundRequestBody?.body,
        response_body: carrierData?.response_body,
        callback_type: carrierData?.callback_type,
        later: carrierData?.later,
        ...(carrierData?.media_type && { media_type: carrierData?.media_type }),
      },
      inbound: {
        body: inbound?.body.map((item) => ({
          key: item?.key,
          value: item?.param,
        })),
      },
      webhook_path: inbound?.hook,
    };

    // console.log("Request Body Final", requestBody);

    saveCarrierFn({ req: requestBody, token });
  };

  useEffect(() => {
    if (status === "completed") {
      if (data) {
        toast.success("Carrier data saved successfully.");
        onCreated(data);
      }
    }
  }, [data, status, error]);

  return (
    <Paper
      sx={{
        padding: "12px",
      }}
    >
      <MDBox>
        <MDBox display="flex" justifyContent="flex-end">
          <BackButton onClick={onBack} text={"back"} />
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          backgroundColor: "#fbfbfb",
          padding: "12px",
          marginTop: "8px",
        }}
      >
        <Typography>Connect External API </Typography>
        <Typography fontSize={"16px"}>{AppConstants.CARRIER_INFO}</Typography>
      </MDBox>
      <CarrierForm
        onSubmit={onSubmit}
        carrierData={carrierData}
        setCarrierData={setCarrierData}
        inbound={inbound}
        setInbound={setInbound}
      />
    </Paper>
  );
};

export default AddCarrier;
