import { Typography, IconButton, Tooltip } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { toast } from "react-toastify";
import { INBOUND_CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";
import { CARRIER_INS } from "constants/AppConstants"; // Import CARRIER_INS
import { BASE_URL } from "constants/Endpoints"; // Import BLIP_GATEWAY
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { memo, useState, useEffect } from "react";

const InboundConfig = ({ setInbound, inbound, carrierData }) => {
  // Initialize the inboundData with dynamic keys from passed inbound data or fallback to INBOUND_CARRIER_REQ_PARAMS_OPT
  const [inboundData, setInboundData] = useState({
    editHook: inbound?.editHook,
    hook: inbound?.hook,
    body: inbound?.body?.length
      ? inbound.body
      : INBOUND_CARRIER_REQ_PARAMS_OPT.map((option) => ({
          key: "", // User will input the key
          param: option.key, // Static values for Key Map from INBOUND_CARRIER_REQ_PARAMS_OPT
        })),
  });

  const updateKeyValue = (index, field, newValue) => {
    setInboundData((prev) => {
      const updatedBody = prev.body.map((item, idx) =>
        idx === index ? { ...item, [field]: newValue } : item
      );
      return { ...prev, body: updatedBody };
    });
  };

  useEffect(() => {
    setInbound(inboundData); // Sync inboundData with parent component
  }, [inboundData, setInbound]);


  // useEffect to decide and sync webhook dynamically with carrier name for Add Carrier fn
  useEffect(() => {
    inbound?.editHook &&
      setInboundData((prev) => {
        return {
          ...prev,
          hook: `${BASE_URL}/phone/carrier/${carrierData.carrier_name
            .replace(/ /g, "_")
            .toLowerCase()}/hook`,
        };
      }); // Sync inboundData with parent component
  }, [carrierData]);

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="center">
        <Typography variant="h5" m={2}>
          {"Inbound configurations"}
        </Typography>
      </MDBox>

      <MDBox
        margin={"8px 8px 12px 8px"}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <MDBox width="100%!important" display="flex" flexDirection="column">
          <MDBox display="flex" alignItems="center">
            <MDInput
              disabled={true}
              fullWidth
              type="text"
              label="Webhook"
              value={inboundData.hook}
            />
            <Tooltip title="Copy Webhook URL">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(inboundData.hook).then(() => {
                    toast.success("Webhook URL copied to clipboard!");
                  });
                }}
              >
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </MDBox>
          <Typography mt={1} fontSize={"14px"} fontWeight={600}>
            {CARRIER_INS.WEBHOOK_INS}
          </Typography>
        </MDBox>

        <MDBox display="flex" width="100%" flexDirection="column" mt={2}>
          {inboundData.body.map((item, index) => (
            <MDBox
              key={`body_${index}`}
              display="flex"
              flexDirection="row"
              alignItems="center"
              mb={2}
            >
              {/* Key Field - Editable */}
              <MDBox flex={2} padding="4px">
                <MDInput
                  fullWidth
                  label="Key"
                  value={item.key}
                  onChange={(e) => updateKeyValue(index, "key", e.target.value)}
                />
              </MDBox>

              {/* Key Map (Param) Field - Static from INBOUND_CARRIER_REQ_PARAMS_OPT */}
              <MDBox flex={1} padding="4px">
                <MDInput
                  fullWidth
                  disabled
                  label="Key Map"
                  value={item.param} // Static value from INBOUND_CARRIER_REQ_PARAMS_OPT
                />
              </MDBox>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default memo(InboundConfig);
