import { useState, useEffect } from "react";
import { Paper, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import BackButton from "ui/BackButton";
import { getCarrier, editCarrier } from "services/CarrierAPI"; // API calls for fetching and saving carrier data
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CarrierForm from "./CarrierForm"; // Reuse CarrierForm for handling the fields
import { INBOUND_CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";
import { BLIP_GATEWAY } from "constants/Endpoints";
import AppConstants from "constants/AppConstants";
import useHttp from "hooks/use-http";
import { loaderAction } from "store/loader-slice";

const EditCarrierForm = ({ carrierId, onBack, onEdited }) => {
  const [isLoading, setIsLoading] = useState(true);
  const token = useSelector((state) => state.user.token); // Get token from Redux store
  const dispatch = useDispatch();

  const {
    sendRequest: editCarrierFn,
    data,
    status,
    error,
  } = useHttp(editCarrier, false);

  // State to hold carrier data for editing
  const [carrierData, setCarrierData] = useState({
    carrier_name: "",
    http_method: "POST",
    content_type: "application/json",
    max_length: 160,
    send_url: "",
    request_body: {},
    response_body: "",
    limit: 0,
  });

  // State for inbound config
  const [inbound, setInbound] = useState({
    hook: `${BLIP_GATEWAY}/sms/inbound`,
    body: INBOUND_CARRIER_REQ_PARAMS_OPT.map((option) => ({
      key: "",
      param: option.key,
    })),
  });

  // Helper function to check if a value is empty
  const isEmpty = (value) => {
    return value == null || (typeof value === "string" && value.trim() === "");
  };

  // Fetch existing carrier data when component mounts
  useEffect(() => {
    const fetchCarrierData = async () => {
      console.log("Edit Form Called");
      try {
        dispatch(loaderAction.show());
        const fetchedCarrier = await getCarrier(carrierId, token); // Fetch carrier data by ID

        const outboundRequestBody = fetchedCarrier?.outbound || {};

        console.log(
          "main Carrier DFata",
          fetchedCarrier?.outbound?.response_body
        );

        //  This fn prepares auth object from auth key value that comes from API to satisfy our Auth Param Component
        const revertAuthObject = (authObject) => {
          if (!authObject || !authObject.type) {
            return {
              name: "No Auth",
              key: "no_auth",
              value: "",
            };
          }

          if (authObject.type === "BASIC") {
            return {
              name: "Basic Auth",
              key: "basic_auth",
              value: {
                userName: authObject.username || "",
                pass: authObject.password || "",
              },
            };
          }

          if (authObject.type === "BEARER") {
            return {
              name: "Bearer Token",
              key: "bearer_token",
              value: authObject.token || "",
            };
          }

          return {
            name: "No Auth",
            key: "no_auth",
            param: "",
          };
        };
        const headers = (
          outboundRequestBody.headers || [{ key: "", value: "", param: "" }]
        ).map((item, index) => ({
          type: "headers",
          key: item.key || "",
          value: item.value || "",
          param: item.param || "",
          id: index,
        }));
        const params = (
          outboundRequestBody.params || [{ key: "", value: "", param: "" }]
        ).map((item, index) => ({
          type: "params",
          key: item.key || "",
          value: item.value || "",
          param: item.param || "",
          id: index,
        }));
        const body = (
          outboundRequestBody.body || [{ key: "", value: "", param: "" }]
        ).map((item, index) => ({
          type: "body",
          key: item.key || "",
          value: item.value || "",
          param: item.param || "",
          id: index,
        }));

        // console.log("Header", headers, params, body);

        // Set the fetched carrier data into state for editing
        setCarrierData({
          carrier_name: fetchedCarrier?.carrier_name,
          http_method: fetchedCarrier?.outbound?.http_method || "POST",
          content_type: fetchedCarrier?.content_type || "application/json",
          max_length: fetchedCarrier?.max_length || 160,
          send_url: fetchedCarrier?.outbound?.api_endpoint || "",
          request_body: {
            headers,
            params,
            body,
            auth: revertAuthObject(outboundRequestBody.auth),
          },
          callback_type: fetchedCarrier?.outbound?.callback_type || "immediate",
          later: fetchedCarrier?.outbound?.later || "",
          media_type: fetchedCarrier?.outbound?.media_type || "",
          response_body: JSON.parse(
            fetchedCarrier?.outbound?.response_body || {}
          ),
          limit: fetchedCarrier?.limit || 0,
          mediaModalClose: false,
        });

        // Map inbound data to the format expected by the form (already uses param)
        setInbound({
          hook: fetchedCarrier?.webhook_path || "",
          body:
            fetchedCarrier?.inbound?.body.map((item, index) => ({
              key: item.key || "",
              param: item.value || "",
              id: index,
            })) ||
            INBOUND_CARRIER_REQ_PARAMS_OPT.map((option, index) => ({
              key: "",
              param: option.key,
              id: index,
            })),
          editHook: false,
        });

        dispatch(loaderAction.hide());
        setIsLoading(false);
      } catch (error) {
        console.log("Error", error);
        toast.error("Failed to load carrier data.");
        dispatch(loaderAction.hide());
      }
    };

    fetchCarrierData();
  }, []);

  // Handle form submission
  const onSubmit = (req) => {
    const outboundRequestBody = carrierData?.request_body;

    const validateParams = () => {
      const selectedParams = new Set();
      const keysSet = new Set();
      const duplicates = new Set();
      const keyValue = inbound?.body;

      keyValue.forEach((item) => {
        // Check if param exists and value is not empty
        if (!isEmpty(item?.param) && !isEmpty(item?.key)) {
          selectedParams.add(item.param);
        }

        // Track duplicate keys
        if (item.key) {
          if (keysSet.has(item.key)) {
            duplicates.add(item.key);
          } else {
            keysSet.add(item.key);
          }
        }
      });

      // Track the missing params by comparing to required options
      const missingParams = INBOUND_CARRIER_REQ_PARAMS_OPT.filter(
        (option) => !selectedParams.has(option.key) && option.key !== "media"
      );

      return { missingParams, duplicates: [...duplicates] };
    };

    const getAuthObject = () => {
      const authObject = outboundRequestBody?.auth;

      console.log("auth Key", authObject);

      if (authObject.key === "basic_auth") {
        return {
          type: "BASIC",
          username: authObject?.value?.userName,
          password: authObject?.value?.pass,
        };
      }

      if (authObject.key === "bearer_token") {
        return {
          type: "BEARER",
          token: authObject.value,
        };
      }

      return {
        type: "NONE",
      };
    };

    // Perform validation
    if (isEmpty(carrierData.carrier_name)) {
      toast.error("Please fill Carrier Name");
      return;
    }

    if (isEmpty(carrierData?.response_body)) {
      toast.error("Response body Not Found in Outbound Config");
      return;
    }

    // Perform validation before making the request
    const { missingParams, duplicates } = validateParams();

    console.log("Values", missingParams, duplicates, inbound);

    if (missingParams.length > 0) {
      const missingKeys = missingParams.map((param) => param.value).join(", ");
      toast.error(
        `Please define values in Inbound Section for the following params: ${missingKeys}`
      );
      return;
    }

    if (duplicates.length > 0) {
      const duplicateKeys = duplicates.join(", ");
      toast.error(
        `Duplicate keys found in Inbound Section: ${duplicateKeys}. Keys must be unique.`
      );
      return;
    }

    // Preparing the Request Body for Save Carrier API
    const requestBody = {
      id: carrierId,
      carrier_name: carrierData?.carrier_name,
      limit: carrierData?.limit,
      outbound: {
        http_method: carrierData?.http_method,
        api_endpoint: carrierData?.send_url,
        headers: outboundRequestBody?.headers,
        auth: getAuthObject(),
        params: outboundRequestBody?.params,
        body: outboundRequestBody?.body,
        response_body: JSON.stringify(carrierData?.response_body, null, 2),
        callback_type: carrierData?.callback_type,
        later: carrierData?.later,
        ...(carrierData?.media_type && { media_type: carrierData?.media_type }),
      },
      inbound: {
        body: inbound?.body.map((item) => ({
          key: item?.key,
          value: item?.param,
        })),
      },
    };

    // console.log("Final Edit Save", requestBody);
    editCarrierFn({ req: requestBody, token });
  };

  useEffect(() => {
    if (status === "completed" && data) {
      toast.success("Carrier data updated successfully.");
      onEdited(data);
      return;
    }
    if (status === "completed" && !data) {
      toast.error("Something went wrong");
    }
  }, [data, status, error]);

  // Here we prevent the Carrier Form Component to render with null or empty values to prevent unnecessary side effects (Note: This logic can be improved)
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Paper
      sx={{
        padding: "12px",
      }}
    >
      <MDBox>
        <MDBox display="flex" justifyContent="flex-end">
          <BackButton onClick={onBack} text={"back"} />
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          backgroundColor: "#fbfbfb",
          padding: "12px",
          marginTop: "8px",
        }}
      >
        <Typography>Edit External Carrier API </Typography>
        <Typography fontSize={"16px"}>
          {AppConstants.EDIT_CARRIER_INFO}
        </Typography>
      </MDBox>
      <CarrierForm
        onSubmit={onSubmit}
        carrierData={carrierData}
        setCarrierData={setCarrierData}
        inbound={inbound}
        setInbound={setInbound}
      />
    </Paper>
  );
};

export default EditCarrierForm;
