import { Button, Typography } from "@mui/material";
import AppConstants from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CompanyAPI } from "services/CompanyAPI";

const SetCompanyFooter = ({companyId,companyFooter = {},onFooterUpdated = () => {}}) => {

    const token = useSelector(state => state.user.token);

    const [footer, setFooter] = useState(companyFooter); 

    const onSave = useCallback(() => {
        const payload = {
            ...footer,
            company : {
                ...footer?.company,
                company_id : companyId
            }
        }
        CompanyAPI.setComopanyFooter({payload,token}).then(res => {
            toast.success(AppConstants.UPDATED_SUCCESS);
            onFooterUpdated(res.data);
        }).catch(e => {
            toast.error(e?.response?.data?.message);
        })
    },[footer,companyId]);

    // useEffect(() => {
    //     if(companyFooter){
    //         setFooter(companyFooter)
    //     }
    // },[companyFooter])

    return(
        <MDBox width="100%" margin={"8px"}>
            <MDBox>
                <MDInput
                    multiline
                    minRows={2}
                    sx={{ marginRight: "8px" }}
                    fullWidth
                    onChange={(e) => {
                        setFooter(prev => {
                        return{
                            ...prev,
                            footer_content : e.target.value
                        }
                        })
                    }}
                    type="text"
                    label="Footer"
                    value={footer?.footer_content}
                />
                <Typography margin={"8px"} fontSize={"12px"}>
                    The content that will be shown as footer in receipt.
                </Typography>
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end">
                <Button
                    onClick={onSave}
                    variant="contained"
                    sx={{
                    borderRadius: "0px",
                    color: "white!important",
                    border: "unset",
                    marginLeft: "12px",
                    height: "32px",
                    minHeight: "unset",
                }}>
                    {"Save"}
                </Button>
            </MDBox>
        </MDBox>
    )
}
export default SetCompanyFooter;