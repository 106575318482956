import { createSlice } from "@reduxjs/toolkit";
import { All_VIEWS } from "constants/ChatConstants";
import { VIEWS } from "constants/ChatConstants";
import { recipientExist } from "util/ChatUtil";

const initialChatStates = {
  unreadCount: 0,
  current_active_phone: undefined,
  NORMAL_VIEW: {
    recipients: [],
    currentPage: 0,
    totalPages: 0,
    fetched: [],
  },
  SCHEDULED_VIEW: {
    recipients: [],
    currentPage: 0,
    totalPages: 0,
    fetched: [],
  },
  HIPAA_VIEW: {
    recipients: [],
    currentPage: 0,
    totalPages: 0,
    fetched: [],
  },
  UNREAD_VIEW: {
    recipients: [],
    currentPage: 0,
    totalPages: 0,
    fetched: [],
  },
  MERGED_VIEW: {
    recipients: [],
    currentPage: 0,
    totalPages: 0,
    fetched: [],
  },
  SEARCH_RECIPIENT_VIEW: {
    recipients: [],
    currentPage: 0,
    totalPages: 0,
    fetched: [],
  },
  wa_templates: [],
  current_active_recipient: undefined,
  current_message_view: "SMS",
  current_last_msg_type: "SMS",
  current_active_view: VIEWS.NORMAL_VIEW,
  stompClient: undefined,
  current_reply_user: undefined,
  recipientsFetched: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState: initialChatStates,
  reducers: {
    bringRecipientToTop: (state, action) => {
      const { recipient } = action.payload;
      const newArray = state.NORMAL_VIEW.recipients.filter(
        (itm) => itm.id !== recipient.id
      );
      newArray.unshift(recipient);
      state.NORMAL_VIEW.recipients = newArray;
    },

    updateUnreadCountToZeroForRecipient: (state, action) => {
      const { recipientId } = action.payload;
      if (!recipientId) return;
      const currentView = state.current_active_view;
      const nrecipientIndex = state.NORMAL_VIEW?.recipients.findIndex(
        (itm) => itm.id === recipientId
      );
      const crecipientIndex = state[currentView]?.recipients.findIndex(
        (itm) => itm.id === recipientId
      );

      try {
        if (crecipientIndex !== -1) {
          const oldCount =
            state[currentView]?.recipients[crecipientIndex]?.unreadCount;
          state[currentView].recipients[crecipientIndex].unreadCount = 0;
          // If all are read for this recipient need to decrement the total count..
          // if old count + count <=0, decrement total count by one
          if (oldCount > 0) {
            const oldTotal = state.unreadCount;
            state.unreadCount = Math.max(oldTotal - 1, 0);
          }
        }
        if (nrecipientIndex !== -1 && currentView !== "NORMAL_VIEW") {
          state.NORMAL_VIEW.recipients[nrecipientIndex].unreadCount = 0;
        }
      } catch (e) {}
    },

    updateUnreadCount: (state, action) => {
      const { recipientId, count } = action.payload;
      const currentView = state.current_active_view;
      const nrecipientIndex = state.NORMAL_VIEW?.recipients.findIndex(
        (itm) => itm.id === recipientId
      );
      const crecipientIndex = state[currentView]?.recipients.findIndex(
        (itm) => itm.id === recipientId
      );
      if (crecipientIndex !== -1) {
        const oldCount =
          state[currentView].recipients[crecipientIndex].unreadCount;
        state[currentView].recipients[crecipientIndex].unreadCount = Math.max(
          oldCount + count,
          0
        );
        // If all are read for this recipient need to decrement the total count..
        // if old count + count <=0, decrement total count by one
        if (oldCount + count <= 0) {
          const oldTotal = state.unreadCount;
          state.unreadCount = Math.max(oldTotal - 1, 0);
        }
      }
      if (nrecipientIndex !== -1 && currentView !== "NORMAL_VIEW") {
        const oldCount =
          state.NORMAL_VIEW.recipients[nrecipientIndex].unreadCount;
        state.NORMAL_VIEW.recipients[nrecipientIndex].unreadCount = Math.max(
          oldCount + count,
          0
        );
      }
    },

    setTotalUnreadCount: (state, action) => {
      const { count } = action.payload;
      state.unreadCount = count;
    },

    concatRecipients: (state, action) => {
      const { view, recipients, totalPages } = action.payload;
      const oldRecipients = state[view].recipients;
      state[view].recipients = oldRecipients.concat(recipients);
      state[view].totalPages = totalPages;
    },

    addRecipientsToFirst: (state, action) => {
      state.NORMAL_VIEW.recipients = [
        action.payload,
        ...state.NORMAL_VIEW.recipients,
      ];
    },

    handleSearchRecipient: (state, action) => {
      const { recipient } = action.payload;
      //TODO:: may be view have to take in consideration
      const findRecipient = state.NORMAL_VIEW?.recipients.find(
        (itm) => itm.id === recipient.id
      );
      // IF available just bring it to top
      if (findRecipient) {
        const newArray = state.NORMAL_VIEW?.recipients.filter(
          (itm) => itm.id !== recipient.id
        );
        newArray.unshift(recipient);
        state.NORMAL_VIEW.recipients = newArray;
      }
      // // Else add new recipient
      else {
        state.NORMAL_VIEW.recipients = [
          recipient,
          ...state.NORMAL_VIEW.recipients,
        ];
      }
      state.current_active_view = VIEWS.NORMAL_VIEW;
      state.current_active_recipient = recipient;
    },

    handleSearchRecipientInMergeView: (state, action) => {
      const { recipient } = action.payload;
      //TODO:: may be view have to take in consideration
      const findRecipient = state.MERGED_VIEW?.recipients.find(
        (itm) => itm.id === recipient.id
      );
      // IF available just bring it to top
      if (findRecipient) {
        const arr = state.MERGED_VIEW?.recipients;
        const newArray = arr.filter((itm) => itm.id !== recipient.id);
        newArray.unshift(recipient);
        console.log(newArray);
        state.MERGED_VIEW.recipients = newArray;
      }
      // // Else add new recipient
      else {
        state.MERGED_VIEW.recipients = [
          recipient,
          ...state.MERGED_VIEW.recipients,
        ];
      }
      state.current_active_view = VIEWS.MERGED_VIEW;
      state.current_active_recipient = recipient;
    },

    setPhoneRecipients: (state, action) => {
      //check if phone has already fetched...
      const { view, recipients, currentPage, totalPages, phoneId } =
        action.payload;
      const oldPhonesFetched = state[view]?.fetched;
      state[view].recipients = [...recipients];
      state[view].currentPage = currentPage;
      state[view].totalPages = totalPages;
      state[view].fetched = [...oldPhonesFetched, phoneId];
    },
    moveRecipientToTop: (state, { payload }) => {
      const view = state.current_active_view;
      const _recipient = state[view].recipients.find(
        (_r) => _r.phone === payload.phone
      );
      if (_recipient) {
        const _recipientList = state[view].recipients.filter(
          (_r) => _r.phone !== payload.phone
        );
        state[view].recipients = [_recipient, ..._recipientList];
      }
    },
    updateCurrentPage: (state, action) => {
      const { view } = action.payload;
      if (state[view]) {
        // data exists ok update now
        const oldCurrPage = state[view].currentPage;
        state[view].currentPage = oldCurrPage + 1;
      }
    },

    // this update the latest message for recipients in left panel
    updateLatestMessage: (state, action) => {
      const { recipientId, message } = action.payload;
      if (recipientId) {
        // update the latest messsage also
        const latesMsg = {
          id: message.id,
          message: message.message,
          createdAt: message.createdAt,
        };
        const currentActiveView = state.current_active_view;
        const cindx = state[currentActiveView].recipients.findIndex(
          (itm) => itm.id === recipientId
        );
        if (cindx !== -1) {
          state[currentActiveView].recipients[cindx].latestMessage = latesMsg;
        }
        if (currentActiveView !== VIEWS.NORMAL_VIEW) {
          const nindx = state.NORMAL_VIEW.recipients.findIndex(
            (itm) => itm.id === recipientId
          );
          if (nindx !== -1) {
            state.NORMAL_VIEW.recipients[nindx].latestMessage = latesMsg;
          }
        }
      }
    },

    updateWhenInboundReceived: (state, action) => {
      const oldTotalUnreadCount = state.unreadCount;
      const currentActiveView = state.current_active_view;
      const message = action.payload;
      let tmpR = state[currentActiveView].recipients;
      const isRecipientExist = recipientExist(tmpR, message.sendTo?.phone);
      const latest = {
        id: message.id,
        message: message?.message,
        createdAt: message?.createdAt,
      };
      // New recipient
      if (!isRecipientExist && message?.sendTo) {
        const newRecipient = message.sendTo;
        newRecipient.latestMessage = latest;
        newRecipient.unreadCount = 1;
        state[currentActiveView].recipients = [
          newRecipient,
          ...state[currentActiveView].recipients,
        ];
        state.unreadCount = oldTotalUnreadCount + 1;
        if (currentActiveView !== VIEWS.NORMAL_VIEW) {
          try {
            const recipientExistInNormalView = recipientExist(
              state[VIEWS.NORMAL_VIEW].recipients,
              message.sendTo?.phone
            );
            if (!recipientExistInNormalView) {
              state[VIEWS.NORMAL_VIEW].recipients = [
                newRecipient,
                ...state[VIEWS.NORMAL_VIEW].recipients,
              ];
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
      //Recipient already exists....
      else {
        const recipientId = message.sendTo.id;
        const rIdx = state[currentActiveView].recipients.findIndex(
          (r) => r.id === recipientId
        );

        let recipient = state[currentActiveView].recipients[rIdx];
        const remainingRecipients = state[currentActiveView].recipients.filter(
          (itm) => itm.id !== recipientId
        );
        // If by chance recipient is undefined
        if (!recipient) {
          recipient = {};
        }
        recipient.latestMessage = latest;
        const oldCount = recipient.unreadCount;
        recipient.unreadCount = oldCount + 1;
        // If phone is blocked update that also
        recipient.phoneBlocked = message?.sendTo?.phoneBlocked;
        state[currentActiveView].recipients = [
          recipient,
          ...remainingRecipients,
        ];
        if (currentActiveView !== VIEWS.NORMAL_VIEW) {
          try {
            state[VIEWS.NORMAL_VIEW].recipients[rIdx].latestMessage = latest;
            const nOldCount =
              state[VIEWS.NORMAL_VIEW].recipients[rIdx].unreadCount;
            state[VIEWS.NORMAL_VIEW].recipients[rIdx].unreadCount =
              nOldCount + 1;
            // If phone is blocked update that also
            state[VIEWS.NORMAL_VIEW].recipients[rIdx].phoneBlocked =
              message?.sendTo?.phoneBlocked;
          } catch (e) {}
        }
        if (state.current_active_recipient) {
          state.current_active_recipient.phoneBlocked =
            message?.sendTo?.phoneBlocked;
        }
        if (oldCount === 0) {
          state.unreadCount = oldTotalUnreadCount + 1;
        }
      }
    },

    updateContactName: (state, action) => {
      const currentView = state.current_active_view;
      const { contact } = action.payload;
      const rIdx = state[currentView].recipients.findIndex(
        (itm) => itm.id === contact.id
      );
      if (rIdx !== -1) {
        state[currentView].recipients[rIdx].displayName = contact?.displayName;
      }

      if (currentView !== VIEWS.NORMAL_VIEW) {
        const nIdx = state[VIEWS.NORMAL_VIEW].recipients.findIndex(
          (itm) => itm.id === contact.id
        );
        if (nIdx !== -1) {
          state[VIEWS.NORMAL_VIEW].recipients[nIdx].displayName =
            contact.displayName;
        }
      }
      // if it is the current active chat
      const currentActiveChat = state.current_active_recipient;
      if (currentActiveChat?.id === contact.id) {
        state.current_active_recipient.displayName = contact?.displayName;
      }
    },

    setCurrentMessageView: (state, action) => {
      state.current_message_view = action.payload;
    },
    setCurrentMessageType: (state, action) => {
      state.current_last_msg_type = action.payload;
    },

    setCurrentActivePhone: (state, action) => {
      state.current_active_phone = action.payload;
    },

    setCurrentActiveRecipient: (state, action) => {
      state.current_active_recipient = action.payload;
    },

    setRecipientById: (state, action) => {
      const recipientId = action.payload;
      const views = [
        VIEWS.NORMAL_VIEW,
        VIEWS.SCHEDULED_VIEW,
        VIEWS.HIPAA_VIEW,
        VIEWS.UNREAD_VIEW,
        VIEWS.MERGED_VIEW,
      ];

      let recipient = null;

      // Search through all views for the recipient by ID
      for (const view of views) {
        recipient = state[view].recipients.find((r) => r.id === recipientId);
        if (recipient) break;
      }

      // If recipient is found, set it as active
      if (recipient) {
        state.current_active_recipient = recipient;

        // Optionally bring the recipient to the top of the NORMAL_VIEW
        const newArray = state.NORMAL_VIEW.recipients.filter(
          (itm) => itm.id !== recipient.id
        );
        newArray.unshift(recipient);
        state.NORMAL_VIEW.recipients = newArray;

        state.current_active_view = VIEWS.NORMAL_VIEW;
      } else {
        console.warn(`Recipient with ID ${recipientId} not found in any view.`);
      }
    },

    setCurrentActiveView: (state, action) => {
      console.log(action.payload);
      state.current_active_view = action.payload;
    },

    updateStompClient: (state, action) => {
      state.stompClient = action.payload;
    },

    resetStomp: (state) => {
      state.stompClient = undefined;
    },

    toggleMute: (state, action) => {
      const { muted, recipientId } = action.payload;
      const currentView = state.current_active_view;
      const index = state[currentView].recipients.findIndex(
        (r) => r.id === recipientId
      );
      if (index !== -1) {
        state[currentView].recipients[index].muted = muted;
      }
    },

    updateAssignTo: (state, action) => {
      const { recipientId, user } = action.payload;
      const recipientIndex = state.NORMAL_VIEW.recipients.findIndex(
        (itm) => itm.id === recipientId
      );
      if (recipientIndex !== -1) {
        state.NORMAL_VIEW.recipients[recipientIndex].currentAssignedTo = user;
        const latestMessage = {
          ...state.NORMAL_VIEW.recipients[recipientIndex].latestMessage,
          message: user ? "Conversation assigned." : "Conversation unassigned.",
        };
        state.NORMAL_VIEW.recipients[recipientIndex].latestMessage =
          latestMessage;
      }
    },

    assigNewConversation: (state, action) => {
      const { contactId, assignTo } = action.payload;
      const contactIdx = state.NORMAL_VIEW.recipients.findIndex(
        (itm) => itm.id === contactId
      );
      if (contactIdx !== -1) {
        const newAssign = {};
        if (assignTo.name) {
          newAssign.name = assignTo.name;
          state.NORMAL_VIEW.recipients[contactIdx].currentAssignedGroup =
            newAssign;
        } else {
          newAssign.fullName = assignTo.fullName;
          state.NORMAL_VIEW.recipients[contactIdx].currentAssignedTo =
            newAssign;
        }
      }
    },

    unassignConversation: (state, action) => {
      const { contactId } = action.payload;
      const contactIdx = state.NORMAL_VIEW.recipients.findIndex(
        (itm) => itm.id === contactId
      );
      if (contactIdx !== -1) {
        state.NORMAL_VIEW.recipients[contactIdx].currentAssignedTo = null;
        state.NORMAL_VIEW.recipients[contactIdx].currentAssignedToGroup = null;
      }
    },

    setReplying: (state, action) => {
      const { payload } = action.payload;
      state.current_reply_user = payload;
    },

    setRecipientFetched: (state, action) => {
      state.recipientsFetched = action.payload;
    },

    addRecipientTags: (state, action) => {
      const { recipientId, tag } = action.payload;
      console.log({ recipientId, tag });
      const idx = state.NORMAL_VIEW.recipients.findIndex(
        (itm) => itm.id === recipientId
      );
      if (idx !== -1) {
        state.NORMAL_VIEW.recipients[idx].tags?.push(tag);
      }
    },

    removeRecipientTags: (state, action) => {
      const { recipientId, tag } = action.payload;
      const idx = state.NORMAL_VIEW.recipients.findIndex(
        (itm) => itm.id === recipientId
      );
      if (idx !== -1) {
        const filtered = state.NORMAL_VIEW.recipients[idx]?.tags?.filter(
          (t) => t?.id !== tag?.id
        );
        state.NORMAL_VIEW.recipients[idx].tags = filtered;
      }
    },

    blockContact: (state, action) => {
      const id = action.payload;
      const recipientIdx = state.NORMAL_VIEW.recipients.findIndex(
        (itm) => itm.id === id
      );
      if (recipientIdx !== -1) {
        state.NORMAL_VIEW.recipients[recipientIdx].contactBlocked = true;
      }
    },

    deleteContact: (state, action) => {
      const id = action.payload;
      const newRecipients = state.NORMAL_VIEW.recipients.filter(
        (itm) => itm.id !== id
      );
      state.NORMAL_VIEW.recipients = newRecipients;
    },

    resetAllRecipients: (state) => {
      for (const v of All_VIEWS) {
        state[v] = {
          recipients: [],
          currentPage: 0,
          totalPages: 0,
          fetched: [],
        };
      }
    },

    unblockRecipients: (state, action) => {
      const recipient = action.payload;
      const recipientIdx = state.NORMAL_VIEW.recipients.findIndex(
        (itm) => itm.id === recipient.id
      );
      if (recipientIdx !== -1) {
        state.NORMAL_VIEW.recipients[recipientIdx].contactBlocked = false;
      }
    },

    deleteRecipientConversation: (state, action) => {
      const { ids } = action.payload;
      //Delete for every view
      for (const v of All_VIEWS) {
        const tmpCon = state[v].recipients;
        //REMOVE LATEST MESSAGE FOR DELETED
        let map = {};
        ids.forEach((id) => (map[id] = true));
        for (const r of tmpCon) {
          if (map[r.id] === true) {
            r.latestMessage = null;
            if (r.unreadCount > 0) {
              r.unreadCount = 0;
              state.unreadCount = Math.max(state.unreadCount - 1, 0);
            }
          }
        }
      }
    },
    setWATemplates: (state, action) => {
      state.wa_templates = action.payload;
    },
    resetChats: (state) => {
      const initialStateVal = {
        recipients: [],
        currentPage: 0,
        totalPages: 0,
        fetched: [],
      };
      state.current_active_recipient = undefined;
      state.NORMAL_VIEW = initialStateVal;
      state.MERGED_VIEW = initialStateVal;
      state.HIPAA_VIEW = initialStateVal;
      state.SCHEDULED_VIEW = initialStateVal;
      state.SEARCH_RECIPIENT_VIEW = initialStateVal;
      state.UNREAD_VIEW = initialStateVal;
      state.current_reply_user = undefined;
      state.recipientsFetched = false;
    },
    reset: () => initialChatStates,
  },
});

export const chatAction = chatSlice.actions;
export default chatSlice;
